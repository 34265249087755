import { AxiosResponse } from 'axios';
import { axiosBaseInstance } from './config';
import { Term } from '../redux/types/terms';

const getTerms = async (): Promise<Term> => {
  const response: AxiosResponse<any> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'get',
    url: `/terms_latest`,
  });
  return response?.data;
};

const acceptTerms = async (term_id?: number, user_id?: number): Promise<any> => {
  const response: AxiosResponse<any> = await axiosBaseInstance({
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: `/user_terms`,
    data: {
      user_term: {
        term_id,
        user_id,
      },
    },
  });
  return response;
};

const termsService = {
  getTerms,
  acceptTerms,
};

export default termsService;
