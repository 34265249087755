import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface Props {
  content: string;
}

const Terms: FC<Props> = (props: Props) => {
  return (
    <Box sx={{ marginX: '5vw' }}>
      <Box sx={{ marginY: '16px' }}>
        <Typography variant="h5">Terminos y Condiciones</Typography>
      </Box>
      <Box
        sx={{
          border: 'solid 1px #7be7c6',
          borderRadius: '8px',
          padding: '16px',
          background: 'rgba(180, 180, 180, 0.3)',
          height: '50vh',
          overflow: 'scroll',
        }}>
        <div className="md">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{props.content}</ReactMarkdown>
        </div>
      </Box>
    </Box>
  );
};

export default Terms;
