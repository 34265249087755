import React, { FC } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import NavBar from './NavBar';
import Login from '../screens/Login';
import Register from '../screens/Register';
import ChatNTalkScreen from '../screens/ChatNTalk';
import Waiting from '../screens/Waiting';
import RecoverPassword from '../screens/RecoverPassword';
import ResetPassword from '../screens/ResetPassword';
import TermsAndConditions from '../screens/TermsAndConditions';

const myRoutes: FC = () => {
  const { token, business_id } = useSelector((state: RootState) => state.user);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={token ? <Navigate to="/waiting" /> : <Register />} />
        <Route path="/login" element={token ? <Navigate to="/waiting" /> : <Login />} />
        <Route path="/register" element={token ? <Navigate to="/waiting" /> : <Register />} />
        <Route path="/recover_password/:mail" element={<RecoverPassword />} />
        <Route path="/reset_password/:email/:token" element={<ResetPassword />} />
        <Route path="/chat" element={business_id && token ? <ChatNTalkScreen /> : <Navigate to="/login" />} />
        <Route path="/waiting" element={<Waiting />} />
        <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
      </Routes>
      {token && business_id && <NavBar />}
    </Router>
  );
};

export default myRoutes;
