import React, { FC, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import userService from '../../services/UserServices';
import { userActions } from '../../redux/slices/userSlice';
import { Box, TextField, Button } from '@mui/material';
import logo_vrt from './../../images/logo_vrt.png';

const UserLogin: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loginError, setLoginError] = useState('');
  const changeEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setEmailError('');
    } else {
      setEmailError('Formato no válido');
    }
    setEmail(event.target.value.toLocaleLowerCase());
  };
  const changePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
  };
  const login = async () => {
    try {
      const response = await userService.loginUser({ email, password });
      if (response) {
        const { user } = response;
        user.token = response.token;
        user.redirect = response.redirect;
        dispatch(userActions.login(user));
      } else {
        setLoginError('Contraseña y/o email incorrectos, intenta denuevo');
        setPassword('');
      }
    } catch (error) {
      setLoginError('Contraseña y/o email incorrectos, intenta denuevo');
      setPassword('');
    }
  };
  const register = () => {
    navigate('/register');
  };
  const business_login = () => {
    navigate('/business_login');
  };
  const recover = () => {
    if (email === '' || emailError !== '') {
      setEmailError('Ingresa tu email para recuperar la contraseña');
    } else {
      userService.recoverPassword(email);
      navigate(`/recover_password/${email}`);
    }
  };
  return (
    <Box sx={{ minWidth: '320px', display: 'inline-block', padding: '5%' }}>
      <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo_vrt} width={'200px'} />
      </Box>
      <TextField
        sx={{ width: '100%', marginY: '2%' }}
        label="Email"
        variant="outlined"
        value={email}
        onChange={changeEmail}
        error={emailError !== ''}
        helperText={emailError}
        inputProps={{ inputMode: 'email' }}
      />
      <TextField
        sx={{ width: '100%', marginY: '2%' }}
        label="Contraseña"
        type="password"
        autoComplete="current-password"
        value={password}
        onChange={changePassword}
        error={loginError !== ''}
        helperText={loginError}
      />
      <Button
        onClick={login}
        size="large"
        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
        variant="contained">
        INICIAR SESION
      </Button>
      <Button
        type="button"
        onClick={register}
        size="large"
        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold' }}
        variant="text">
        No tienes cuenta? Registrate ahora
      </Button>
      <Button
        type="button"
        onClick={recover}
        size="large"
        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold' }}
        variant="text">
        Recupera tu contraseña
      </Button>
      <Button
        type="button"
        onClick={business_login}
        size="small"
        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', color: '#ddd' }}
        variant="text">
        Ingreso de Empresas
      </Button>
    </Box>
  );
};

export default UserLogin;
