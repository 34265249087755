import React, { FC } from 'react';
import Routes from './navigation/Routes';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import DefaultTheme from './styles/DefaultTheme';
import './App.css';

const App: FC = () => {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <CssBaseline />
      <div className="container">
        <div className="App">
          <Routes />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
