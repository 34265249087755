import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';

const Timer: FC = () => {
  const [redirectUrl, setRedirectUrl] = useState('https://install.page/activida');

  useEffect(() => {
    const isIPhone = /iPhone/i.test(navigator.userAgent);
    const redirectUrl = isIPhone
      ? 'https://apps.apple.com/cl/app/activida/id6737854865' // Reemplaza con el enlace real de tu app en la App Store
      : 'https://install.page/activida';
    setRedirectUrl(redirectUrl);
    window.location.href = redirectUrl;
  }, []);

  return (
    <Box sx={{ color: '#1b4ed8', textAlign: 'center' }}>
      <h1 className="App-logo-text">
        Espera, estas siendo redireccionado a <a href={redirectUrl}>instalar la App</a>
      </h1>
    </Box>
  );
};

export default Timer;
