import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { Box, Button, TextField } from '@mui/material';
import userService from '../services/UserServices';
import { useNavigate, useParams } from 'react-router-dom';
import { userActions } from '../redux/slices/userSlice';
import { useDispatch } from 'react-redux';

const RecoverPassword: FC = () => {
  const [email, setEmail] = useState<string | undefined>('');
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mail } = useParams();
  const [minutes, setMinutes] = useState(0);

  const startDate = Date.now();

  const getTime = () => {
    const time = Date.now() - startDate;
    setMinutes(Math.floor((time / 1000 / 60) % 60));
  };

  useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch(userActions.logout());
    setEmail(mail);
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  const recover = () => {
    userService.recoverPassword(email);
  };
  const changeEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setEmailError('');
    } else {
      setEmailError('Formato no válido');
    }
    setEmail(event.target.value.toLocaleLowerCase());
  };
  const login = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        textAlign: 'left',
      }}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box sx={{ padding: '5vh' }}>
          <Box sx={{ paddingY: '5vh' }}>
            <h2 className="App-logo-text">Revisa tu mail y sigue las Instrucciones</h2>
            <Box>
              <h4 className="App-logo-text">Si en 5 minutos NO has recibido un mail, presiona Reenviar Email</h4>
            </Box>
          </Box>
          <TextField
            sx={{ width: '100%', marginY: '2%' }}
            label="Email"
            variant="outlined"
            value={email}
            onChange={changeEmail}
            error={emailError !== ''}
            helperText={emailError}
          />
          <Box sx={{ marginY: '5vh' }}>
            <Button
              disabled={minutes < 1}
              onClick={recover}
              size="large"
              sx={{ borderRadius: '50px', width: '100%', marginY: '2%', fontWeight: 'bold' }}
              variant="contained">
              REENVIAR EMAIL
            </Button>
          </Box>
          <Box sx={{ marginY: '5vh' }}>
            <Button size="large" onClick={login} sx={{ width: '100%', marginY: '2%', fontWeight: 'bold' }}>
              Volver al inicio
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RecoverPassword;
