import React, { SyntheticEvent, Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Paper } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const styles = {
  navbar: {
    backgroundColor: 'primary.main',
    width: '100vw',
    paddingBottom: '25px',
    paddingTop: '25px',
    borderRadius: '20px 20px 0 0',
    '& .MuiBottomNavigationAction-root, .Mui-selected, svg': {
      color: 'white',
      transition: 'all .25s ease-in-out',
      marginBottom: '12px',
      borderRadius: '100%',
    },
    '& .Mui-selected': {
      color: 'primary.main',
    },
    '& .Mui-selected > svg': {
      fill: 'primary.main',
      color: 'primary.main',
      backgroundImage: 'linear-gradient(#7be7c6, #3EA8E3)',
      border: 'solid 4px #1b4ed8',
      padding: '10px',
      width: '60px',
      height: '60px',
    },
  },
};

export default function LabelBottomNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { mode } = useSelector((state: RootState) => state.user);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };
  const navValues = ['/home', '/body', 'chat', '/profile'];
  return (
    <Fragment>
      {navValues.includes(location.pathname) === true && (
        <Paper
          sx={{
            background: 'transparent',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: '10000',
          }}
          elevation={2}>
          <BottomNavigation sx={styles.navbar} value={location.pathname} onChange={handleChange}>
            <BottomNavigationAction value="/home" icon={<HomeIcon />} />
            <BottomNavigationAction value="/body" icon={<AccessibilityIcon />} />
            <BottomNavigationAction value="/profile" icon={<PersonIcon />} />
            <BottomNavigationAction value={mode !== 'viewer' ? '/out' : '/waiting'} icon={<ExitToAppIcon />} />
          </BottomNavigation>
        </Paper>
      )}
    </Fragment>
  );
}
