import React, { FC, useState, useEffect } from 'react';
import Terms from './Terms';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/slices/userSlice';
import { Term } from '../../redux/types/terms';
import { Box, Checkbox, FormControlLabel, Button } from '@mui/material';
import termsService from '../../services/TermsServices';

const AcceptTerms: FC = () => {
  const [terms, setTerms] = useState<Term | null>(null);
  const [accept, setAccept] = useState(false);
  const user_id = useSelector((state: RootState) => state.user.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    termsService.getTerms().then((response) => setTerms(response));
  }, []);

  const onClick = () => {
    const term_id = terms?.id || NaN;
    termsService
      .acceptTerms(term_id, user_id)
      .then(() => dispatch(userActions.redirect({ redirect: 'false' })))
      .then(() => navigate('/waiting'));
  };

  return (
    <Box>
      <Terms content={terms?.content || ''} />
      <Box sx={{ marginX: '5vw' }}>
        <FormControlLabel
          sx={{ marginY: '16px' }}
          onChange={() => setAccept(accept === false)}
          control={<Checkbox checked={accept} />}
          label="He leido y acepto los términos y condiciones de uso"
        />
        <Button
          sx={{ width: '100%', borderRadius: '50px', fontWeight: 'bold', boxShadow: 'none' }}
          variant="contained"
          size="large"
          disabled={accept === false}
          onClick={onClick}>
          Continuar
        </Button>
      </Box>
    </Box>
  );
};

export default AcceptTerms;
