export const formatValue = (value: string) => {
  let regex2;
  let regex1;
  if (value.length >= 2) {
    regex1 = /[^0-9]/g;
    regex2 = /[^0-9k]/g;
    const valueToVerify = value.slice(0, -1).replace(regex1, '');
    const verifyingDigit = value.charAt(value.length - 1).replace(regex2, '');
    const cleanValue = valueToVerify + verifyingDigit;
    return `${cleanValue.slice(0, -1)}-${cleanValue.charAt(cleanValue.length - 1)}`;
  }
  return value;
};

export const dgv = (T: number) => {
  let M = 0;
  let S = 1;
  // eslint-disable-next-line no-param-reassign
  for (; T; T = Math.floor(T / 10)) {
    S = (S + (T % 10) * (9 - (M % 6))) % 11;
    M += 1;
  }
  return S ? S - 1 : 'k';
};
